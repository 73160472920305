var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-poll","color":"secondary","title":"Reports"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-select',{attrs:{"label":"Policy Status","loading":_vm.loadingMixins.policyStatuses,"items":_vm.mixinItems.policyStatuses,"item-text":"name","item-value":"id","multiple":"","prepend-icon":"mdi-list-status"},model:{value:(_vm.policyReport.status_type_policy_id),callback:function ($$v) {_vm.$set(_vm.policyReport, "status_type_policy_id", $$v)},expression:"policyReport.status_type_policy_id"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-select',{attrs:{"label":"Policy Type","loading":_vm.loadingMixins.policyTypes,"items":_vm.mixinItems.policyTypes,"item-text":"name","item-value":"id","multiple":"","prepend-icon":"mdi-watermark"},model:{value:(_vm.policyReport.type),callback:function ($$v) {_vm.$set(_vm.policyReport, "type", $$v)},expression:"policyReport.type"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-select',{attrs:{"label":"Agent","loading":_vm.loadingMixins.agents,"items":_vm.mixinItems.agents,"item-text":_vm.showAgentName,"item-value":"id","multiple":"","prepend-icon":"mdi-face-agent"},model:{value:(_vm.policyReport.agent),callback:function ($$v) {_vm.$set(_vm.policyReport, "agent", $$v)},expression:"policyReport.agent"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Effective Date From","prepend-icon":"mdi-calendar-clock","readonly":""},model:{value:(_vm.policyReport.effective_date_from),callback:function ($$v) {_vm.$set(_vm.policyReport, "effective_date_from", $$v)},expression:"policyReport.effective_date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.effectiveDateFrom),callback:function ($$v) {_vm.effectiveDateFrom=$$v},expression:"effectiveDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){_vm.effectiveDateFrom = false}},model:{value:(_vm.policyReport.effective_date_from),callback:function ($$v) {_vm.$set(_vm.policyReport, "effective_date_from", $$v)},expression:"policyReport.effective_date_from"}})],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Effective Date To","prepend-icon":"mdi-calendar-clock","readonly":""},model:{value:(_vm.policyReport.effective_date_to),callback:function ($$v) {_vm.$set(_vm.policyReport, "effective_date_to", $$v)},expression:"policyReport.effective_date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.effectiveDateTo),callback:function ($$v) {_vm.effectiveDateTo=$$v},expression:"effectiveDateTo"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){_vm.effectiveDateTo = false}},model:{value:(_vm.policyReport.effective_date_to),callback:function ($$v) {_vm.$set(_vm.policyReport, "effective_date_to", $$v)},expression:"policyReport.effective_date_to"}})],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Expiration Date From","prepend-icon":"mdi-calendar-refresh"},model:{value:(_vm.policyReport.expiration_date_from),callback:function ($$v) {_vm.$set(_vm.policyReport, "expiration_date_from", $$v)},expression:"policyReport.expiration_date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.expirationDateFrom),callback:function ($$v) {_vm.expirationDateFrom=$$v},expression:"expirationDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){_vm.expirationDateFrom = false}},model:{value:(_vm.policyReport.expiration_date_from),callback:function ($$v) {_vm.$set(_vm.policyReport, "expiration_date_from", $$v)},expression:"policyReport.expiration_date_from"}})],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Expiration Date To","prepend-icon":"mdi-calendar-refresh"},model:{value:(_vm.policyReport.expiration_date_to),callback:function ($$v) {_vm.$set(_vm.policyReport, "expiration_date_to", $$v)},expression:"policyReport.expiration_date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.expirationDateTo),callback:function ($$v) {_vm.expirationDateTo=$$v},expression:"expirationDateTo"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){_vm.expirationDateTo = false}},model:{value:(_vm.policyReport.expiration_date_to),callback:function ($$v) {_vm.$set(_vm.policyReport, "expiration_date_to", $$v)},expression:"policyReport.expiration_date_to"}})],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"loading":_vm.fetching,"color":"success"},on:{"click":_vm.fetchPolicyReport}},[_vm._v(" Generate Report ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }