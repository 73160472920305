<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-poll"
      color="secondary"
      title="Reports"
    >
      <v-row>
        <v-col
          md="4"
          cols="12"
        >
          <v-select
            v-model="policyReport.status_type_policy_id"
            label="Policy Status"
            :loading="loadingMixins.policyStatuses"
            :items="mixinItems.policyStatuses"
            item-text="name"
            item-value="id"
            multiple
            prepend-icon="mdi-list-status"
          />
        </v-col>
        <v-col
          md="4"
          cols="12"
        >
          <v-select
            v-model="policyReport.type"
            label="Policy Type"
            :loading="loadingMixins.policyTypes"
            :items="mixinItems.policyTypes"
            item-text="name"
            item-value="id"
            multiple
            prepend-icon="mdi-watermark"
          />
        </v-col>
        <v-col
          md="4"
          cols="12"
        >
          <v-select
            v-model="policyReport.agent"
            label="Agent"
            :loading="loadingMixins.agents"
            :items="mixinItems.agents"
            :item-text="showAgentName"
            item-value="id"
            multiple
            prepend-icon="mdi-face-agent"
          />
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-menu
            v-model="effectiveDateFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyReport.effective_date_from"
                label="Effective Date From"
                prepend-icon="mdi-calendar-clock"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="policyReport.effective_date_from"
              no-title
              color="primary"
              @input="effectiveDateFrom = false"
            />
          </v-menu>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-menu
            v-model="effectiveDateTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyReport.effective_date_to"
                label="Effective Date To"
                prepend-icon="mdi-calendar-clock"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="policyReport.effective_date_to"
              no-title
              color="primary"
              @input="effectiveDateTo = false"
            />
          </v-menu>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-menu
            v-model="expirationDateFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyReport.expiration_date_from"
                label="Expiration Date From"
                prepend-icon="mdi-calendar-refresh"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="policyReport.expiration_date_from"
              no-title
              color="primary"
              @input="expirationDateFrom = false"
            />
          </v-menu>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-menu
            v-model="expirationDateTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyReport.expiration_date_to"
                label="Expiration Date To"
                prepend-icon="mdi-calendar-refresh"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="policyReport.expiration_date_to"
              no-title
              color="primary"
              @input="expirationDateTo = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="4">
          <v-btn
            :loading="fetching"
            color="success"
            @click="fetchPolicyReport"
          >
            Generate Report
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.policyStatuses,
        MIXINS.policyTypes,
        MIXINS.agents,
      ]),
    ],

    data: () => ({
      policyReport: {
        status_type_policy_id: [],
        type: [],
        agent: [],
        effective_date_from: null,
        effective_date_to: null,
        expiration_date_from: null,
        expiration_date_to: null,
      },
      effectiveDateFrom: false,
      effectiveDateTo: false,
      expirationDateFrom: false,
      expirationDateTo: false,
      fetching: false,
    }),

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async fetchPolicyReport () {
        this.fetching = true
        try {
          const response = await axios.post('reports/policy', { policyReport: this.policyReport })
          if (response.data.message) {
            this.showSnackBar({ text: response.data.message, color: 'warning' })
          } else {
            const rows = []
            const headers = Object.keys(response.data[0])
            rows.push(headers.join(','))
            const values = response.data.map(row => Object.values(row).join(','))
            rows.push(values.join('\n'))
            const data = rows.join('\n')

            const blob = new Blob([data], { type: 'text/csv' })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', 'policy-report.csv')
            a.click()
          }
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.fetching = false
      },

      showAgentName (agent) {
        return agent.first_name + ' ' + agent.last_name
      },
    },
  }
</script>
